import { Stack } from '@rossum/ui/material';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AnimateIn from '../../components/UI/AnimateIn';
import { MIN_FOOTER_HEIGHT } from '../../decorators/makeResizable/config';
import { DocumentCanvas } from '../../features/annotation-view/document-canvas-svg/DocumentCanvas';
import { useCanvasDimensions } from '../../features/annotation-view/document-canvas-svg/useCanvasDimensions';
import { useHandleDefaultSwipe } from '../../features/annotation-view/document-canvas-svg/useHandleDefaultSwipe';
import {
  useCanvasGeometryActions,
  useDocumentStore,
} from '../../features/annotation-view/document-store/DocumentStore';
import { visiblePageZustandSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import { State } from '../../types/state';
import { DrawerConfig } from '../DocumentValidation/ValidationEmailDrawer';
import Footer from '../Footer/Footer';
import {
  getFittingHeightSelector,
  showFooterSelector,
} from '../Footer/selectors';
import SearchPanel from '../SearchPanel';
import DocumentToolbar from './components/DocumentToolbar';
import TopBar from './components/TopBar';
import styles from './style.module.sass';

type DocumentV2Props = {
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

const TOOLBAR_ZOOM_FACTOR = 0.2;

export const DocumentV2 = ({ onEmailThreadOpen }: DocumentV2Props) => {
  const initialHeight = useSelector(getFittingHeightSelector);
  const [footerHeight, setFooterHeight] = useState(initialHeight);
  const showFooter = useSelector(showFooterSelector);
  const pages = useSelector((state: State) => state.pages.pages);
  const dimensions = useCanvasDimensions(pages);
  const { zoomBy, resetZoom } = useCanvasGeometryActions();

  const { pageNumber: visiblePageNumber } = useDocumentStore(
    visiblePageZustandSelector(dimensions)
  );

  const matrixActions = useCanvasGeometryActions();

  const nPages = pages.length;

  const showSearchPanel = useSelector(
    (state: State) => state.search.shouldShow
  );

  const navigateToPage = useCallback(() => {
    const isOnLastPage = nPages === visiblePageNumber;

    return isOnLastPage
      ? matrixActions.translateTo({ y: 0 })
      : matrixActions.translateBy({ x: 0, y: dimensions.canvas.height + 1 });
  }, [nPages, visiblePageNumber, matrixActions, dimensions.canvas]);

  const documentRef = useRef<HTMLDivElement>(null);

  useHandleDefaultSwipe(documentRef.current);

  return (
    <Stack sx={{ height: '100%' }}>
      <TopBar
        navigateToPage={navigateToPage}
        visiblePageNumber={visiblePageNumber}
        onEmailThreadOpen={onEmailThreadOpen}
      />
      <Stack
        direction="row"
        sx={{ height: '100%', width: '100%', flex: '1 1 auto' }}
      >
        <Stack className={styles.DocumentWrapper} id="document">
          <AnimateIn
            display={showSearchPanel}
            beforeAnimationClassName={styles.HiddenOnTop}
            animationClassName={styles.SlideFromTop}
          >
            <SearchPanel />
          </AnimateIn>
          <div
            ref={documentRef}
            className={styles.WorkingArea}
            data-tourstep="dataCaptureProductTour-document"
          >
            <DocumentCanvas
              pages={pages}
              data-tourstep="dataCaptureProductTour-missing-bbox"
              dimensions={dimensions}
            />
            <DocumentToolbar
              footerHeight={footerHeight}
              increaseZoom={() => {
                zoomBy(+TOOLBAR_ZOOM_FACTOR, undefined);
              }}
              decreaseZoom={() => {
                zoomBy(-TOOLBAR_ZOOM_FACTOR, undefined);
              }}
              resetZoom={() => {
                resetZoom();
              }}
            />
          </div>
          {showFooter && (
            <Footer
              minHeight={MIN_FOOTER_HEIGHT}
              setHeight={setFooterHeight}
              height={initialHeight}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
