import { Collapse, Divider, Stack } from '@rossum/ui/material';
import { Dispatch, memo, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import InviteColleague from '../../../components/InviteColleague';
import { ResizablePane } from '../../../components/ResizablePane/ResizablePane';
import { DOCUMENTS_SIDEBAR_WIDTH } from '../../../redux/modules/localStorage/actions';
import { isTrialSelector } from '../../../redux/modules/organization/selectors';
import { State } from '../../../types/state';
import { SidebarQueues } from '..';
import AllDocumentItem from './components/AllDocumentItem';
import Toolbar from './components/Toolbar';

export type DashboardModalsState = {
  addingQueue: boolean;
  addingWorkspace: boolean;
  dialogResetCounter: number;
};

type Props = {
  isSidebarOpen: boolean;
  setDashboardState: Dispatch<SetStateAction<DashboardModalsState>>;
  currentQueueId?: number;
  onQueueChange: (queueId: number) => void;
  setAllDocumentsLevelActive: () => void;
  level: 'all' | 'queue' | 'initial' | null;
};
// scrolling gets broken

const Sidebar = memo(
  ({
    onQueueChange,
    isSidebarOpen,
    setDashboardState,
    currentQueueId,
    setAllDocumentsLevelActive,
    level,
  }: Props) => {
    const isTrial = useSelector((state: State) => isTrialSelector(state));

    const [searchValue, setSearchValue] = useState('');

    const handleOnAddQueueClick = () =>
      setDashboardState(state => ({
        ...state,
        addingQueue: true,
        dialogResetCounter: state.dialogResetCounter + 1,
      }));

    const handleOnAddWorkspaceClick = () => {
      setDashboardState(state => ({
        ...state,
        addingWorkspace: true,
        dialogResetCounter: state.dialogResetCounter + 1,
      }));
    };

    return (
      <Collapse in={isSidebarOpen} orientation="horizontal" sx={{ zIndex: 1 }}>
        <ResizablePane storageKey={DOCUMENTS_SIDEBAR_WIDTH}>
          <Stack direction="row" height={1} position="relative" width={1}>
            <Stack
              width={1}
              flexDirection="column"
              sx={{
                height: '100%',
                borderRadius: 0,
                backgroundColor: 'background.paper',
              }}
            >
              <AllDocumentItem
                isActive={level === 'all'}
                handleOnClick={setAllDocumentsLevelActive}
                onAddQueueClick={handleOnAddQueueClick}
                onAddWorkspaceClick={handleOnAddWorkspaceClick}
              />
              <Divider />
              <Toolbar
                searchValue={searchValue}
                onSearchChange={value => setSearchValue(value)}
              />

              <SidebarQueues
                currentQueueId={currentQueueId}
                onQueueChange={onQueueChange}
                setAllDocumentsLevelActive={setAllDocumentsLevelActive}
                searchValue={searchValue}
              />

              <Divider />

              {isTrial && (
                <Stack
                  py={4}
                  pb={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <InviteColleague />
                </Stack>
              )}
            </Stack>

            <Divider orientation="vertical" flexItem />
          </Stack>
        </ResizablePane>
      </Collapse>
    );
  }
);

export default Sidebar;
