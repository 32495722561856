import { useEffect, useRef } from 'react';
import { IDENTITY_MATRIX_2D } from '../../features/annotation-view/document-canvas/utils/geometry';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { matrixToState } from '../../features/annotation-view/document-store/helpers';

export const ViewportResizeObserver = () => {
  const setViewportDimensions = useDocumentStore(
    state => state.setViewportDimensions
  );

  const setViewportState = useDocumentStore(state => state.setViewportState);

  const viewportRef = useDocumentStore(state => state.viewportRef);

  const resizeObserverRef = useRef<ResizeObserver>(
    new ResizeObserver(([entry]) => {
      if (entry.target instanceof SVGSVGElement) {
        setViewportDimensions(entry.contentRect);

        const newState = matrixToState(
          entry.target.getScreenCTM() ?? IDENTITY_MATRIX_2D
        );
        setViewportState(newState);
      }
    })
  );

  const viewport = viewportRef.current;
  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;

    if (viewport) {
      resizeObserver.observe(viewport);
    }

    return () => {
      if (viewport) {
        resizeObserver.unobserve(viewport);
      }
    };
  }, [viewport]);

  return null;
};
