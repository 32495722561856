import { combineEpics } from 'redux-observable';
import { filter, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import { getName } from '../user/helpers';
import { isActionOf, makeEpic } from '../utils';
import { reportProblem } from './actions';

const openReportModal = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(reportProblem)),
    withLatestFrom(state$),
    tap(([_, state]) => {
      const { organization, organizationGroup, user } = state;

      window.FreshworksWidget('identify', 'ticketForm', {
        name: getName(user),
        email: user.email,
      });
      window.FreshworksWidget('prefill', 'ticketForm', {
        custom_fields: {
          cf_django_org_idenv: String(organization.id),
          cf_is_production: !!organizationGroup.current?.isProduction,
          cf_deployment: organizationGroup.current?.deploymentLocation,
        },
      });
      window.FreshworksWidget('hide', 'ticketForm', [
        'custom_fields.cf_django_org_idenv',
        'custom_fields.cf_is_production',
        'custom_fields.cf_deployment',
      ]);

      window.FreshworksWidget('open', 'ticketForm');
    }),
    mapTo({ type: 'EMPTY_ACTION' })
  )
);

export default combineEpics(openReportModal);
