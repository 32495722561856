import { Button, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardShortcut } from '../../components/UI/KeyboardShortcut';
import { createDatapoint } from '../../redux/modules/datapoints/actions';
import {
  currentMultivalueDatapointSelector,
  shouldFocusAddValueSelector,
} from '../../redux/modules/datapoints/selector';
import { suggestedOperationsOptionsSelector } from '../../redux/modules/datapoints/suggestedOperations/selector';
import { State } from '../../types/state';

// Current footer also handles maxOccurences constraint, but it is intentionally omitted here,
// since it's also not enforced by the grid.
export const FooterAddLineButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const multivalue = useSelector(currentMultivalueDatapointSelector);

  const disableEditingDatapoints = useSelector((state: State) => {
    return (
      state.ui.readOnly ||
      suggestedOperationsOptionsSelector(state).rowsCount > 0 ||
      !!state.ui.actionInProgress
    );
  });

  const shouldFocus = useSelector(shouldFocusAddValueSelector);

  if (!multivalue) {
    return null;
  }

  /* AFI: some kind of waiting state? It should also scroll down after a new line is added */
  return (
    <Tooltip
      title={
        disableEditingDatapoints ? (
          ''
        ) : (
          <KeyboardShortcut
            keyName="ctrlShiftA"
            description={intl.formatMessage({
              id: 'components.documentValidation.footer.inputs.insert.tuple',
            })}
          />
        )
      }
    >
      <Button
        variant="outlined"
        color="secondary"
        data-cy="annotation-footer-addline"
        onClick={() => dispatch(createDatapoint(multivalue.meta.index))}
        disabled={disableEditingDatapoints}
        // For testing
        className={shouldFocus ? 'AddlinesButtonFocused' : ''}
        sx={{
          boxShadow: shouldFocus
            ? theme =>
                `0 0 0 2px ${theme.palette.background.paper}, 0 0 0 4px ${theme.palette.primary.main}`
            : 'none',
        }}
      >
        {intl.formatMessage({
          id: 'components.documentValidation.footer.inputs.insert.tuple',
        })}
      </Button>
    </Tooltip>
  );
};
