import { createSelector } from 'reselect';
import { MIN_FOOTER_HEIGHT } from '../../decorators/makeResizable/config';
import { getFittingHeight } from '../../decorators/makeResizable/helpers';
import {
  currentMultivalueDatapointSelector,
  getTableDatapointsWithIds,
  isFooterOpenSelector,
} from '../../redux/modules/datapoints/selector';
import { State } from '../../types/state';

// AFI: Refactor this together with withResizable
export const getFittingHeightSelector = createSelector(
  getTableDatapointsWithIds,
  currentMultivalueDatapointSelector,
  state => state.datapoints.aggregations,
  ([tuples], currentMultivalueDatapoint, aggregations) => {
    return currentMultivalueDatapoint
      ? getFittingHeight(tuples, !!aggregations[currentMultivalueDatapoint.id])
      : MIN_FOOTER_HEIGHT;
  }
);

const editModeActiveSelector = (state: State) => state.ui.editModeActive;

export const showFooterSelector = createSelector(
  isFooterOpenSelector,
  editModeActiveSelector,
  (isFooterOpen, editModeActive) => isFooterOpen && !editModeActive
);
