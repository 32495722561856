import { Queue } from '@rossum/api-client/queues';
import {
  CloudDownload,
  Delete,
  DeleteForever,
  Download,
  DriveFileMove,
  ExitToApp,
  FilePresentRounded,
  Label,
  Output,
  Refresh,
  RestorePageRounded,
  SvgIconComponent,
  Visibility,
  WatchLater,
} from '@rossum/ui/icons';
import { fromPairs } from 'lodash';
import { LocalizationKeys } from '../../../i18n';
import {
  AnnotationAction,
  isDeletable,
  isDownloadable,
  isPostponable,
  isPurgeable,
  isReExtractable,
  isReprocessable,
  isReviewable,
  isSwichtableToExport,
} from '../../../redux/modules/annotation/helpers';
import { Status } from '../../../types/annotationStatus';

export type SelectionPanelButtonAction = Exclude<
  AnnotationAction,
  'downloadFile' | 'upload' | 'addAttachment'
>;

type PanelButtonMeta = {
  localization: LocalizationKeys;
  dataCy: string;
  isPrimaryAction: boolean;
};

type ActionParamsFromQueue = Pick<Queue, 'useConfirmedState' | 'url'> & {
  hideExportButton: Queue['settings']['hideExportButton'];
};
// TODO mission-simplified-dashboard
// not sure whether this shouldn't be memoized in some way
export const getSupportedBatchActions = <
  T extends { status: Status; queue: string; restricted_access?: boolean },
>(
  annotations: Array<T>,
  actionParamsFromQueue: Array<ActionParamsFromQueue>,
  labelsEnabled: boolean,
  canUserPurge: boolean
) => {
  // Shows export button on confirmed tab
  const showExportButton = fromPairs(
    actionParamsFromQueue.map(({ url, hideExportButton }) => [
      url,
      !hideExportButton,
    ])
  );
  const useConfirmedState = fromPairs(
    actionParamsFromQueue.map(({ url, useConfirmedState }) => [
      url,
      useConfirmedState ?? false,
    ])
  );

  const actions: Record<SelectionPanelButtonAction, boolean> = {
    download: annotations.every(
      ({ status, restricted_access }) =>
        isDownloadable(status) && !restricted_access
    ),
    downloadAndExport: annotations.every(
      ({ status, queue, restricted_access }) =>
        isSwichtableToExport(status) &&
        showExportButton[queue] &&
        !restricted_access
    ),
    downloadForAllStatuses: annotations.every(
      ({ restricted_access }) => !restricted_access
    ),
    validate: annotations.every(
      ({ status, restricted_access }) =>
        isReviewable(status) && !restricted_access
    ),
    postpone: annotations.every(
      ({ status, restricted_access }) =>
        isPostponable(status) && !restricted_access
    ),
    reprocess: annotations.every(
      ({ status, queue, restricted_access }) =>
        isReprocessable(status) &&
        !useConfirmedState[queue] &&
        !restricted_access
    ),
    reprocessConfirmed: annotations.every(
      ({ status, queue, restricted_access }) =>
        isReprocessable(status) &&
        useConfirmedState[queue] &&
        !restricted_access
    ),
    move: true,
    purge:
      annotations.every(({ status }) => isPurgeable(status)) && canUserPurge,
    delete: annotations.every(({ status }) => isDeletable(status)),
    reExtract: annotations.every(
      ({ status, restricted_access }) =>
        isReExtractable(status) && !restricted_access
    ),
    label:
      annotations.every(({ restricted_access }) => !restricted_access) &&
      labelsEnabled,
  };

  return actions;
};

export const panelButtonMeta: Record<
  SelectionPanelButtonAction,
  PanelButtonMeta
> = {
  validate: {
    localization: 'containers.annotationList.selectionPanel.buttons.review',
    dataCy: 'review-eye-icon',
    isPrimaryAction: true,
  },
  reprocess: {
    localization: 'components.annotationOverview.actionButtons.reprocessStatus',
    dataCy: 'reprocess-refresh-icon',
    isPrimaryAction: false,
  },
  reprocessConfirmed: {
    localization:
      'components.annotationOverview.actionButtons.reprocessConfirmedStatus',
    dataCy: 'reprocess-refresh-icon',
    isPrimaryAction: false,
  },
  postpone: {
    localization: 'containers.annotationList.selectionPanel.buttons.postpone',
    dataCy: 'postpone-clock-icon',
    isPrimaryAction: false,
  },
  move: {
    localization: 'components.annotationOverview.actionButtons.move',
    dataCy: 'move-to-icon',
    isPrimaryAction: false,
  },
  reExtract: {
    localization: 'containers.annotationList.selectionPanel.buttons.reExtract',
    dataCy: 'batch-re-extract-button',
    isPrimaryAction: false,
  },
  delete: {
    localization: 'containers.annotationList.selectionPanel.buttons.delete',
    dataCy: 'batch-delete-button',
    isPrimaryAction: false,
  },
  purge: {
    localization: 'containers.annotationList.selectionPanel.buttons.purge',
    dataCy: 'batch-purge-button',
    isPrimaryAction: false,
  },
  label: {
    localization: 'containers.annotationList.selectionPanel.buttons.label',
    dataCy: 'batch-label-button',
    isPrimaryAction: false,
  },
  download: {
    localization: 'containers.annotationList.selectionPanel.buttons.download',
    dataCy: 'download-all-icon',
    isPrimaryAction: true,
  },
  downloadForAllStatuses: {
    localization: 'containers.annotationList.selectionPanel.buttons.download',
    dataCy: 'download-all-icon',
    isPrimaryAction: false,
  },
  downloadAndExport: {
    localization: 'containers.annotationList.selectionPanel.buttons.export',
    dataCy: 'export-all-icon',
    isPrimaryAction: false,
  },
};

export const actionIcons: Record<AnnotationAction, SvgIconComponent> = {
  download: CloudDownload,
  downloadForAllStatuses: CloudDownload,
  downloadFile: Download,
  downloadAndExport: Output,
  upload: ExitToApp,
  label: Label,
  validate: Visibility,
  reprocess: Refresh,
  reprocessConfirmed: Refresh,
  postpone: WatchLater,
  move: DriveFileMove,
  delete: Delete,
  purge: DeleteForever,
  reExtract: RestorePageRounded,
  addAttachment: FilePresentRounded,
};
